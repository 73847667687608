<template>
  <div class="dp-pro-recipe-taste">
    <p class="dp-pro-recipe-taste__description">
      {{ contents.capsule.description }}
    </p>

    <div class="dp-pro-recipe-taste__map">
      <DpTasteMap
        :category-type="contents.category_type"
        :capsule-position="contents.capsule.map_position_id"
        :recipe-position="contents.map_position_id" />
    </div>

    <DpTasteMapLegend />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpTasteMapLegend: () =>
      import('@/components/dripPod/core/TasteMapLegend.vue'),

    DpTasteMap: () => import('@/components/dripPod/proRecipe/TasteMap.vue')
  },

  props: {
    contents: {
      type: Object,
      default: null
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-taste {
  &:last-child {
    margin-bottom: 0;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__description {
    margin-bottom: 16px;
    line-height: 1.6;
  }

  &__map {
    margin-bottom: 16px;
  }
}
</style>
